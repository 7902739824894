<template>
    <div class="flex">
        <el-form-item :label="$t('calendar.start_date')" prop="startDate" class="mr-4">
            <el-date-picker
                v-model="startDate"
                type="date"
                :clearable="false"
                :editable="false"
                :picker-options="{ firstDayOfWeek: 1 }"
                :placeholder="$t('calendar.start_date')"
                format="dd.MM.yyyy"
                @change="startDateChanged"
            />
        </el-form-item>
        <el-form-item :label="$t('calendar.end_date')" :class="{ 'hidden': recursive === 0 }" class="transition" prop="endDate">
            <el-date-picker
                v-model="endDate"
                type="date"
                :editable="false"
                :picker-options="{ firstDayOfWeek: 1 }"
                :placeholder="$t('calendar.without_end_date')"
                format="dd.MM.yyyy"
                @change="endDateChanged"
            />
        </el-form-item>
    </div>
</template>
<script>
export default {
    props: {
        modalVisible: {
            type:    Boolean,
            default: false,
        },
        recursive: {
            type:    Number,
            default: 0,
        },
    },

    data() {
        return {
            startDate: this.$dayjs(),
            endDate:   null,
        };
    },

    watch: {
        modalVisible: {
            immediate: true,
            handler() {
                this.startDate = this.$store.state.calendar.startDateSelected;
                this.datesChanged();
            },
        },
    },

    methods: {
        startDateChanged() {
            if (this.endDate && this.startDate >= this.endDate) {
                this.endDate = this.$dayjs(this.startDate).add(1, 'day');
            }
            this.datesChanged();
        },

        endDateChanged() {
            if (!this.endDate) return;
            if (this.endDate <= this.startDate) {
                this.startDate = this.$dayjs(this.endDate).subtract(1, 'day');
            }
            this.datesChanged();
        },

        datesChanged() {
            this.$emit('startDateChange', this.startDate);
            this.$emit('endDateChange', this.endDate);
        },
    },
};
</script>
<style>
.dialog-add-event .el-date-editor.el-input,
.dialog-add-event .el-date-editor.el-input__inner {
    width: initial !important;
    max-width: 185px;
}
</style>
